import React, { Component } from 'react';
import styles from '../components/cssModules/Home.module.css';
import About from '../components/About';

class Home extends Component {
  render() {
    return(
      <div className={styles.background} data-aos="fade-up" data-aos-duration="800">
        <div className={styles.backgroundImg}></div>
        <div className={styles.backgroundImgMobile}></div>
        <section>
          <div className={styles.homepageText}>
            <hr className={styles.hrLine}/>
            <h1>
              We are driven by our passion for people and the impact we have when the right people work together.
            </h1>
            <hr className={styles.hrLine}/>
          </div>
          <h1 className={styles.titles} id="aboutUs">About Us</h1>
          <About />
        </section>
      </div>
    )
  }
}

export default Home;

import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { HashLink } from "react-router-hash-link";

import bbLogo from '../media/logo.png';

import styles from '../cssModules/Navigation.module.css';

/**
 * @desc this class includes the UI for the navbar.
 * It also includes Navlinks that makes it possible to navigate 
 * between the screens on our app.
 */
class Navigation extends Component {
  constructor() {
    super();

    this.state = {
      show: true
    }
  };

  hamburgerMenu = () => {
    this.setState({
      show: !this.state.show
    })
  }

  render() {
    return(
      <header className={styles.topnav}>
        <div className={styles.flexbox}>
          <NavLink 
            className={styles.button} 
            activeStyle={{
              color: "#fa6706"
            }}
            // activeClassName={styles.selected} 
            to="/employers">
            Employers
          </NavLink>

          <NavLink 
            className={styles.button} 
            activeStyle={{
              color: "#fa6706"
            }}
            to="/candidates">
            Candidates
          </NavLink>

          <NavLink 
            className={styles.blackbird} 
            // activeClassName={styles.selected} 
            to="/">
            <img src={bbLogo} className={styles.bbLogo} alt="blackbird logo" />
          </NavLink>

          <NavLink 
            className={styles.button} 
            activeStyle={{
              color: "#fa6706"
            }}
            // activeClassName={styles.selected} 
            to="/services">
            Services
          </NavLink>
          
          {/*
          <a className={styles.button} href="mailto:info@bbird.ch">
            Contact us
          </a>
          */}

          <HashLink className={styles.button} to="/#aboutUs">
            About us
          </HashLink>

          {/* <NavLink 
            className={styles.button} 
            // activeClassName={styles.selected} 
            to="/">
            About
          </NavLink> */}

          {/* <NavLink 
            className={styles.button} 
            // activeClassName={styles.selected} 
            to="/contact">
            <button>Contact</button>
          </NavLink> */}    
        </div>
        {/* <div> 
        <NavLink 
            className={styles.blackbird} 
            // activeClassName={styles.selected} 
            to="/">
            <img src={bbLogo} className={styles.bbLogo} alt="blackbird logo" />
          </NavLink>
        </div> */}


        {!this.state.show 
        ? 
        <nav className={styles.mobile}>
            <NavLink 
            className={styles.blackbird} 
            to="/">
            <img src={bbLogo} className={styles.bbLogo} alt="blackbird logo" />
            </NavLink>
          <a className={styles.hamMenu}>
            <i className="fas fa-bars"></i>
          </a>
          <ul>
              <NavLink 
              className={styles.button} 
              activeStyle={{
                color: "#fa6706"
              }}
              to="/employers"
              onClick={this.hamburgerMenu}>
              Employers
              </NavLink>
              <NavLink 
              className={styles.button} 
              activeStyle={{
                color: "#fa6706"
              }}
              to="/candidates"
              onClick={this.hamburgerMenu}>
              Candidates
              </NavLink>

              <NavLink 
              className={styles.button} 
              activeStyle={{
                color: "#fa6706"
              }}
              to="/services"
              onClick={this.hamburgerMenu}>
              Services
              </NavLink>
              <HashLink className={styles.button}  to="/#aboutUs" onClick={this.hamburgerMenu}>
                About us
              </HashLink>
            </ul>
        </nav>
        :
        <nav className={styles.mobile}>
          <NavLink 
          className={styles.blackbird} 
          to="/">
          <img src={bbLogo} className={styles.bbLogo} alt="blackbird logo" />
          </NavLink>
          <a className={styles.hamMenu} >
            <i onClick={this.hamburgerMenu} className="fas fa-bars"></i>
          </a>
        </nav>
        }
      </header>
    )
  }
}

export default Navigation;





import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import './App.css';

import Navigation from './components/Navigation/Navigation';
import Routers from './components/Navigation/Routers';
import Footer from './components/Footer';
import Home from './components/Home';
import Employers from './components/Employers';
import Candidates from './components/Candidates';
import Services from './components/Services';

function App() {
  return (
    <div className="App">
      <Router>
        <Navigation />
        <Route exact path="/" component={Home} />
        <Route exact path="/employers" component={Employers} />
        <Route exact path="/candidates" component={Candidates} />
        <Route exact path="/services" component={Services} />
        
        <Footer />
      </Router>
    </div>
  );
}

export default App;

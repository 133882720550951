import React, { Component, Fragment } from 'react';

import styles from '../components/cssModules/Services.module.css';
import bulb from '../components/media/bulb.png';
import ASI from '../components/media/ASI copy.png';

class Services extends Component {
  render() {
    return(
      <Fragment>        
        <div className={styles.yellowBox} id="pas" data-aos="fade-right" data-aos-duration="2000">
          <h2>Partial Acquisition Services (PAS)</h2>
          <p>
            We adapt to create as much value to our customers as possible. 
            To meet your specific needs, we offer to provide only parts of the full recruitment service. 
            With our expertise we believe these services can open up valuable time for you in demanding situations.
          </p>
          <p>
            We look forward to create solutions together with you.
          </p>
        </div>
        <div className={styles.whiteBox} id="personalAssesments"  data-aos="fade-left" data-aos-delay="500" data-aos-duration="2000">
          <h2>Assessments & Workshops</h2>
          <p>
            Our assessments are powerful instruments that can be used for a number of valuable purposes. 
          </p>
          <p>
            Our tools are excellent for development of individuals and groups. 
            Whether you work independently or in a team, in a specialist function or in leadership, 
            you work in sales or in accounting – we can help you learn more about yourself and how you can 
            develop on many different levels by applying simple strategies.
          </p>
          <p>
            Our workshops provide powerful team building exercises which improve people’s understanding of each other, team spirit, as well as performance and group efforts.
          </p>
          <p>
            We support organizations and individuals to reach their full potential, increase collaboration, 
            and to optimize the communicative environment. Sky is the limit when people work well together!
          </p>
        </div>

        <div className={styles.yellowBox2} id="secondOpinion"  data-aos="fade-right" data-aos-delay="800" data-aos-duration="2000">
          <div className={styles.col1}>
            <img data-aos="zoom-in" data-aos-duration="2000" data-aos-delay="1000" src={bulb} alt="light bulb with DISC puzzle pieces colors inside" />
          </div>
          <div className={styles.col2}>
            <h2>Second Opinion Services</h2>
            <p>
              We understand how difficult it can be to decide between a number of very qualified candidates. 
              With our second opinion services, our aim is to support you at the final stage of your recruitment process. 
              As your objective partner, we assess your final candidates by use of tools and methods to help you make the right decision.
            </p>
            <p>
              By using this approach, you will be supported by valuable input and comprehensive data, 
              which is provided objectively by us as an external party outside your organization and your recruitment process.
            </p>
            <p>
              With this collaboration we would love to provide crucial advise to your final candidate selection, 
              and also introduce opportunities to the integration procedure of your new employee
            </p>
            <p>
              We only work with quality assured assessment tools to analyse a candidates personal dynamics and motivational drive factors. 
              The applicants are given a fair evaluation, and are provided with comprehensive feedback. 
              You are provided with a solid input to make a well-educated decision.
            </p>

          </div>
        </div>
        {/* <div className={styles.whiteBox2}>
          <div className={styles.whiteBoxASI}>
            <h2>ASI Certificate</h2>
            <p>
            ASI facilitates the development and application of the American Psychological Association (APA) 
            standards for educational and psychological testing. Certification by ASI signifies that your testing 
            and assessment procedures meet the association's essential requirements. 
            <a href="https://assessmentinstitute.org/standards"> https://assessmentinstitute.org/standards</a>
            </p>        
          </div>
        </div>
        <img src={ASI} alt="ASI certificate badge" className={styles.ASIImg}/> */}

        <div className={styles.blackbirdDiv}  data-aos="zoom-in" data-aos-duration="2000">
          <div className={styles.blackbirdDivText}>
            <h2>Want to know more?</h2>
            <a className={styles.contactUsDISC} href="mailto:info@bbird.ch">Contact us now!</a>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default Services;

import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import emailjs from 'emailjs-com';

import styles from './cssModules/Candidates.module.css';

class Candidates extends Component {
  render() {
    function sendEmail(e) {
      e.preventDefault();

      emailjs.sendForm('service_6gmugla', 'template_vcr9p7o', e.target, 'user_rzxMBJZx5U8cTE3j3NSh7')
        .then((result) => {
          console.log(result.text);
          alert('Your application has been sent!');
        }, (error) => {
          console.log(error.text);
        });
    }
    
    return(
      <Fragment>
        <section className={styles.container}>
          <div className={styles.col1} data-aos="fade-right" data-aos-duration="1000">
            <h1>Applications</h1>
            <p>
              Blackbird helps you take your career to new heights. We specialize in corporate functions across industries where we add value from our own backgrounds and experiences to be able to explicitly understand what you are looking for.
            </p>
            <p>
              Our aim is to make the recruitment process as efficient as possible for you, and to be with you throughout the full process to reach your goals.
            </p>
            <p>
              Contact us to know more about current opportunities
            </p>
            <form className="contact-form" onSubmit={sendEmail}>
              <input type="hidden" name="contact_number" />

              <label htmlFor="user_name">Name:</label>
              <input name="user_name" type="text" placeholder="Your name" required/>

              <label htmlFor="user_email">Email</label>
              <input type="email" name="user_email" placeholder="Your email" required/>

              <label htmlFor="user_telephone">Telephone number:</label>
              <input type="number" name="user_telephone" placeholder="Your telephone number" required/>

              <label htmlFor="user_position">My ideal job is...</label>
              <select id="positions" name="user_position">
                <option value="Accounting" required>Accounting</option>
                <option value="Audit">Audit</option>
                <option value="Controlling">Controlling</option>
                <option value="FP&amp;A">FP&amp;A</option>
                <option value="Human Resources">Human Resources</option>
                <option value="Supply Chain/Logistics">Supply Chain/Logistics</option>
                <option value="Tax/VAT/Legal">Tax/VAT/Legal</option>
                <option value="Treasury">Treasury</option>
                <option value="Other">Other</option>
              </select>

              <label htmlFor="message">Message</label>
              <textarea name="message" placeholder="Want to say something? Message us here!"/>

              <input className={styles.application_button} id="application_button" type="submit" value="Send your application" />
            </form>
          </div>
          <div className={styles.col2} data-aos="fade-left" data-aos-duration="1000">
            <h1>Personal assessments</h1>
            <p>
            We understand that finding a new job that suits you can be difficult. 
            Blackbird helps guiding you in the right direction by offering personal assessments 
            which provide better understanding of yourself, knowledge of your drivers and 
            motivators, as well as what type of environment that could be most suitable for you to work in.
            </p>
            <p>
            <NavLink 
              className={styles.button} 
              activeStyle={{
                color: "#fa6706"
              }}
              to="/DISC">
              Read more about our assessments.
            </NavLink>
            </p>
          </div>
        </section>
      </Fragment>
    )
  }
}

export default Candidates;

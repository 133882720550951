import React, { Component, Fragment } from 'react';
import { HashLink } from "react-router-hash-link";

import Modal from '../components/Modal';
import styles from './cssModules/Employers.module.css';
import Services from './Services';

class Employers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      clickedBtn: false,
    }
  }

  showModal = () => {
    this.setState({ show: true });
    this.setState({ clickedBtn: true });
    // return (<div className={styles.overlay}></div>);
  };

  closeModal = () => {
    this.setState({ show: false });
    this.setState({ clickedBtn: false });
  };

  render() {
    return(
      <Fragment>
        <section className={styles.container}>
          <div className={styles.col1} data-aos="fade-right" data-aos-duration="1000">
            <h1 className={styles.recH1}>Talent Acquisition</h1>
            <hr className={styles.hrLine} />
            <p className={styles.texts}>
              Our talent acquisition services cover the end-to-end process. 
              Our goal is to provide personalized services where we rely heavily on quality rather than quantity. 
              We use our own personal network, database, referrals, and direct approach to find you the best talent.
            </p>
            <p className={styles.texts}>
              Our consultants are based in Zurich and have a background in their field of recruitment, which allows 
              them to highly benefit from their experience outside of recruitment as well.
            </p>
            <p className={styles.texts}>
              We utilize personal assessment instruments as a cornerstone in our hiring and selection process. 
              This approach helps us to evaluate and understand our candidates far beyond our own interviews and 
              the technical requirements for the role. In addition, it supports the integration of the new employees 
              for a long term win-win. 
            </p>
            <p className={styles.texts}>
              The purpose of this is to find people who will both fit an organizations culture, as well as 
              the people they will work with. We believe this sets a higher quality standard to the recruitment service.
            </p>
            <p className={styles.texts}>
              Contact us to learn more about how we can help you find the right people.
            </p>

            {/* <h2  className={styles.contactUs}>Contact us: info@bbird.ch</h2> */}
            
             {/* MODAL BUTTON  */}
            {/* <button 
              className={styles.employersBtn}
              onClick={this.showModal}
            >
              Recruitment
            </button> */}
           
          </div>

          <div className={styles.col2} data-aos="fade-left" data-aos-duration="1000">
            <h1>Services</h1>
            <hr className={styles.hrLine2} />
            <h2>Partial Acquisition Services (PAS)</h2>
            <p className={styles.textScreening}>
              If you already have a strong TA team, you can outsource only parts of the recruitment process to us. 
              Our ambition is to be as flexible as possible to support you in demanding situations.
            </p>
            <p className={styles.textScreening}>Read more about our Partial Acquistion Services  <HashLink to="/services#pas">here</HashLink>.</p>
            <h2>Assessments & Workshops</h2>
            <p className={styles.textScreening}>
              We offer consulting to individuals, teams, and leaders to improve communication and 
              collaboration at the workplace. Discover new qualities about yourself and your business environment.
            </p>
            <p className={styles.textScreening}>Read more about our assessments  <HashLink to="/services#personalAssesments">here</HashLink>.</p>
            <h2>Second Opinion Services</h2>
            <p className={styles.textScreening}>
              Blackbird can consult you as an objective third party in your selection processes. 
              From our knowledge in personal and group dynamics, we can give valuable advise 
              as support for you to select the right candidates.
            </p>
            <p className={styles.textScreening}>Read more about our second opinion services  <HashLink to="/services#secondOpinion">here</HashLink>.</p>
            {/* MODAL BUTTON*  */}
            {/* <button 
              className={styles.screeningBtn}
              onClick={this.showModal}
            >
              Screening
            </button> */}
           
          </div>

          {/* MODAL */}
          {/* { this.state.clickedBtn ? <div className={styles.overlay}></div> : null }
          { this.state.showModal ? <div onClick={this.closeModal} className={styles.backDrop}></div> : null }

          <Modal
            // className="modal"
            show={this.state.show}
            close={this.closeModal}
          >
            <form action="">
              <input type="text" id="fname" name="fname" placeholder="first name"/>
              <input type="text" id="lname" name="lname" placeholder="last name"/>
              <textarea placeholder="Your thoughts"></textarea>
              <input className={styles.submitBtn} type="submit" value="Send" />
            </form> 
          </Modal> */}
        </section>
      </Fragment>
    )
  }
}

export default Employers;

import React, { Component } from 'react';
import styles from '../components/cssModules/About.module.css';



class About extends Component {
  render() {
    return(
      <div className={styles.aboutusContainer}>
          <p className={styles.aboutusContent}>
            When you do small things right, big things happen. We are a small company based in Zurich.
            Our recruitment approach involves emotional intelligence and human energy. Therefore, our search for talent goes beyond skills, experience and references, and into behaviours, motivators and ways of thinking as well.  
            The way we work is inspired by our enthusiasm of keeping close relationships with all our partners, whether clients or candidates.
            We believe the flair in this industry is based on personal and cultural understanding, and that is why we put the human being at the core of everything we do.
          </p>
      </div>
    )
  }
}

export default About;

// <section className={styles.container}>
// <div className={styles.col1}>
//   <h1>Applications</h1>
//   <form>
//     <label for="name">Name:</label>
//     <input type="text" placeholder="name"/>
//     <label for="name">Email:</label>
//     <input type="email" placeholder="email"/>
//     <label for="phone">Telephone number:</label>
//     <input type="number" placeholder="telephone number"/>
//     <label for="cars">Field:</label>
//     <select id="cars" name="cars">
//       <option value="economics">Economics</option>
//       <option value="it">IT</option>
//       <option value="marketing">Marketing</option>
//       <option value="retail">Retail</option>
//     </select>
//     <textarea></textarea>
//     <button className={styles.applicationsBtn}>Submit</button>
//   </form>
// </div>
// <div className={styles.col2}>
//   <h1>Opening Positions:</h1>
//   <ul>
//     <li>CEO of Moneyz</li>
//     <li>Executive Search Consultant</li>
//     <li>VP Services Operations</li>
//     <li>Chief Technology Officer</li>
//   </ul>
// </div>
// </section>
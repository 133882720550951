import React, { Component } from 'react';

import styles from '../components/cssModules/Footer.module.css';
import bird from '../components/media/bird.gif'

class Footer extends Component {
  render() {
    const styleBackground = {
      backgroundImage: `url(${bird})`,
      width: '100%',
      height: '150px',
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat'
    }
    return(
      <footer>
        <div className={styles.backgroundBird} style={styleBackground}></div>
        <div className={styles.row}>
          <div className={styles.infoContainer}>
            <div className={styles.contactInfo}>
              <h3>Want to hear more over a cup of coffee?</h3>
              {/* <a className={styles.phone} href="tel:+41123456789">+41 12 345 67 89</a> */}
              <a className={styles.phone} href="mailto:info@bbird.ch">info@bbird.ch</a>
              <p className={styles.street}>Bahnhofstrasse 10,</p>
              <p className={styles.street1}>8001 Zürich.</p>
              <a href="tel:00410447208000">Tel: +41 (0) 44 720 80 00</a>
            </div>
          </div>
          {/*
          <div className={styles.socialMedia}>
            <h3>Keep an eye on us</h3>
            <a href="https://linkedin.com"><span className="fab fa-linkedin"></span></a>
            <a href="https://facebook.com"><span className="fab fa-instagram"></span></a>
            <a href="https://instagram.com"><span className="fab fa-twitter-square"></span></a>
          </div>
          */}
        </div>
        <div className={styles.tradeMark}>
          <p>Trademark by Vereva™ GmbH, 2020.</p>
        </div>
      </footer>
    )
  }
}

export default Footer;
